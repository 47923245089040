import { getFiltersFromUrl, isEmpty } from '~/helpers'
import { getProvidersWithTariffs, getTariffs } from '~/graphql/graphql'
import { useMainStore } from '~/store/main/main.store'
import { useCities } from '~/store/cites/cities.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'

export function tariffsComposible() {
  const ctx = useNuxtApp()
  const mainStore = useMainStore()
  const cityStore = useCities()
  const tariffsStore = useTariffs()
  const currentCity = computed(() => cityStore.getCity)
  const $route = useRoute()
  const houseUrl = computed(() => tariffsStore.getHouseUrl)
  const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
  const houseHash = computed(() => tariffsStore.getHouseHash)
  const woFilter = computed(() => mainStore.getWoFilter)
  const providerClient = computed(() => mainStore.getProviderClient)
  const partner = computed(() => mainStore.getDomainConfig)

  const client = ctx._apolloClients.default

  const filters = ref({
    serviceSets: [],
    providers: [],
    tech: [],
    subscriptionServiceIds: [],
    tags: [],
    sort: 'POPULAR',
    subscriptionAny: undefined,
    priceMin: 0,
    priceMax: 0,
    speedMin: 0,
    speedMax: 0,
    smartFilter: ($route.name === 'personal-address-slug' || $route.name === 'city-address-slug') && !woFilter.value && !$route.query.smartFilter ? 'internet' : '',
  })
  const ranges = ref({
    priceMin: false,
    priceMax: false,
    speedMin: false,
    speedMax: false,
  })
  const fastFetchTariffs = async (input?: any, tariffsInput2?: any) => {
    const res = (
      await client.query({
        query: getTariffs,
        variables: {
          input: {
            hash: houseUrl.value
              ? houseHash.value || undefined
              : undefined,
            url: houseUrl.value ? houseUrl.value : undefined,
            addrObjFiasId: currentCity.value.fias_id,
            strictTechs: !!houseUrl.value,
            renameNonUniqueTariffs: !partner.value && (!houseUrl.value || !!(input?.hasOwnProperty('url') && !input?.url)),
            lowerProviders: providerClient.value ? [+providerClient.value] : undefined,
            ...input,
          },
          tariffsInput2,
        },
        context: {
          headers: getCommonHeaders.value,
        },
        fetchPolicy: 'no-cache',
      })
    ).data.location.locationTerms?.tariffs
    return res
  }

  const fetchTariffs = async (input?: any, tariffInput?: any) => {
    let qFilters = await getFiltersFromUrl($route.fullPath)

    if (isEmpty(qFilters)) {
      qFilters = filters.value
      qFilters.houseType = undefined
    }
    const pSmartFilter = computed(() => /city-internet.*/.test($route.name) ? $route.name?.replace('city-', '') : undefined)

    if (!qFilters.smartFilter) qFilters.smartFilter = $route.params.smartFilter || pSmartFilter.value
    if (!qFilters.sort) qFilters.sort = 'POPULAR'

    const res = await fastFetchTariffs(input,
      {
        houseType: qFilters.houseType || undefined,
        sort: qFilters.sort || 'POPULAR',
        page: $route.query.page ? +$route.query.page : 1,
        pageSize: 20,
        gaming: qFilters.gaming ? qFilters.gaming : undefined,
        promo: qFilters.promo ? qFilters.promo : undefined,
        hasWifi: qFilters.hasWifi ? qFilters.hasWifi : undefined,
        routerIncluded: qFilters.routerIncluded
          ? qFilters.routerIncluded
          : undefined,
        freeConnection: qFilters.freeConnection
          ? qFilters.freeConnection
          : undefined,
        providerIds: qFilters.providers?.length
          ? qFilters.providers
          : undefined,
        techIds: qFilters.tech?.length ? qFilters.tech : undefined,
        priceMin: qFilters.priceMin ? +qFilters.priceMin : undefined,
        priceMax: qFilters.priceMax ? +qFilters.priceMax : undefined,
        serviceSets:
              qFilters.serviceSets?.length
              && !(
                qFilters.serviceSets.length === 1
                && qFilters.serviceSets[0] === '2'
              )
                ? [qFilters.serviceSets]
                : undefined,
        serviceSetsContains:
              qFilters.serviceSets?.length === 1
              && qFilters.serviceSets?.[0] === '2'
                ? [qFilters.serviceSets]
                : undefined,
        tags:
              qFilters.tags && qFilters.tags.length ? qFilters.tags : undefined,
        speedMax: qFilters.speedMax ? +qFilters.speedMax : undefined,
        speedMin: qFilters.speedMin ? +qFilters.speedMin : undefined,
        subscriptionAny: qFilters.subscriptionAny
          ? qFilters.subscriptionAny
          : undefined,
        subscriptionService:
              qFilters.subscriptionServiceIds
              && qFilters.subscriptionServiceIds.length
                ? qFilters.subscriptionServiceIds
                : undefined,
        recommended: qFilters.recommend ? qFilters.recommend : undefined,
        fastConnection: qFilters.fastConnection
          ? qFilters.fastConnection
          : undefined,
        smartFilter: qFilters.smartFilter
          ? qFilters.smartFilter
          : undefined,
        popularSortParams: {
          debug: !!$route.query.debug || undefined,
        },
        ...tariffInput,
      })

    return res
  }

  const fetchProvidersWTariffs = async () => {
    let qFilters = await getFiltersFromUrl($route.fullPath)
    if (isEmpty(qFilters)) {
      qFilters = filters.value
      qFilters.houseType = undefined
    }
    if (!qFilters.sort) qFilters.sort = 'POPULAR'
    const qFiltersCount = Object.entries(qFilters)
      .filter(
        (el: any) => !el[0].includes('Range') && !el[0].includes('category'),
      )
      .map((el: any) =>
        el[0].includes('price') || el[0].includes('speed') ? !!el[1] : el[1],
      )
      .filter(
        (el: any) =>
          (!!el && (Array.isArray(el) ? el.length : null)) || el === true,
      )
      .flat().length
    const res = (
      await client.query({
        query: getProvidersWithTariffs,
        variables: {
          input: {
            hash:
              houseUrl.value
                ? houseHash.value || undefined
                : undefined,
            url: houseUrl.value ? houseUrl.value : undefined,
            addrObjFiasId: currentCity.value.fias_id,
            strictTechs: !!houseUrl.value,
            lowerProviders: providerClient.value ? [+providerClient.value] : undefined,
          },
          tariffsInput2: {
            houseType: qFilters.houseType || undefined,
            sort: 'POPULAR',
            page: 1,
            pageSize: 0,
            gaming: qFilters.gaming ? qFilters.gaming : undefined,
            promo: qFilters.promo ? qFilters.promo : undefined,
            routerIncluded: qFilters.routerIncluded
              ? qFilters.routerIncluded
              : undefined,
            freeConnection: qFilters.freeConnection
              ? qFilters.freeConnection
              : undefined,
            techIds: qFilters.tech?.length ? qFilters.tech : undefined,
            priceMin: qFilters.priceMin ? +qFilters.priceMin : undefined,
            priceMax: qFilters.priceMax ? +qFilters.priceMax : undefined,
            serviceSets:
              qFilters.serviceSets?.length
              && !(
                qFilters.serviceSets.length === 1
                && qFilters.serviceSets[0] === '2'
              )
                ? [qFilters.serviceSets]
                : undefined,
            serviceSetsContains:
              qFilters.serviceSets?.length === 1
              && qFilters.serviceSets?.[0] === '2'
                ? [qFilters.serviceSets]
                : undefined,
            tags: qFilters.tags && qFilters.tags.length ? qFilters.tags : undefined,
            speedMax: qFilters.speedMax ? +qFilters.speedMax : undefined,
            speedMin: qFilters.speedMin ? +qFilters.speedMin : undefined,
            subscriptionAny: qFilters.subscriptionAny
              ? qFilters.subscriptionAny
              : undefined,
            subscriptionService:
              qFilters.subscriptionServiceIds
              && qFilters.subscriptionServiceIds.length
                ? qFilters.subscriptionServiceIds
                : undefined,
            subscriptionCategory:
              qFilters.subscriptionIds && qFilters.subscriptionIds.length
                ? qFilters.subscriptionIds
                : undefined,
          },
          providersInput2: {
            tariffless: !(qFiltersCount || houseUrl.value),
            bestTariffsCount: 2,
            sort: 'BEST_TARIFF',
            byTariffs: {
              houseType: qFilters.houseType || undefined,
              sort: qFilters.sort,
              page: 1,
              pageSize: 0,
              gaming: qFilters.gaming ? qFilters.gaming : undefined,
              promo: qFilters.promo ? qFilters.promo : undefined,
              routerIncluded: qFilters.routerIncluded
                ? qFilters.routerIncluded
                : undefined,
              freeConnection: qFilters.freeConnection
                ? qFilters.freeConnection
                : undefined,
              techIds: qFilters.tech?.length ? qFilters.tech : undefined,
              priceMin: qFilters.priceMin ? +qFilters.priceMin : undefined,
              priceMax: qFilters.priceMax ? +qFilters.priceMax : undefined,
              serviceSets:
                qFilters.serviceSets?.length
                && !(
                  qFilters.serviceSets.length === 1
                  && qFilters.serviceSets[0] === '2'
                )
                  ? [qFilters.serviceSets]
                  : undefined,
              serviceSetsContains:
                qFilters.serviceSets?.length === 1
                && qFilters.serviceSets?.[0] === '2'
                  ? [qFilters.serviceSets]
                  : undefined,
              tags:
                qFilters.tags && qFilters.tags.length ? qFilters.tags : undefined,
              speedMax: qFilters.speedMax ? +qFilters.speedMax : undefined,
              speedMin: qFilters.speedMin ? +qFilters.speedMin : undefined,
              smartFilter: qFilters.smartFilter || undefined,
              subscriptionAny: qFilters.subscriptionAny
                ? qFilters.subscriptionAny
                : undefined,
              subscriptionService:
                qFilters.subscriptionServiceIds
                && qFilters.subscriptionServiceIds.length
                  ? qFilters.subscriptionServiceIds
                  : undefined,
              subscriptionCategory:
                qFilters.subscriptionIds && qFilters.subscriptionIds.length
                  ? qFilters.subscriptionIds
                  : undefined,
            },
            offset: 0,
            limit: 50,
          },
        },
        context: {
          headers: getCommonHeaders.value,
        },
        fetchPolicy: 'no-cache',
      })
    ).data.location.locationTerms

    const options = res.tariffs.agg
    if (options?.availableHouseTypes?.length > 1 && !qFilters.houseType) {
      filters.value.houseType
        = !houseUrl.value && options.availableHouseTypes.includes('FLAT')
          ? 'FLAT'
          : ''
    }

    return res
  }

  const setFiltersOnMounted = async (tariffs: any) => {
    const qFilters = await getFiltersFromUrl($route.fullPath)

    if (isEmpty(qFilters)) {
      qFilters.smartFilter = filters.value.smartFilter
    }
    const pSmartFilter = computed(() => /city-internet.*/.test($route.name) ? $route.name?.replace('city-', '') : undefined)

    const smF = tariffs?.agg?.locationAgg?.availableSmartFilters?.find(
      el => el.slug === ($route.params?.smartFilter || pSmartFilter.value || qFilters.smartFilter),
    )

    if (tariffs?.agg?.availableHouseTypes?.length > 1 && !qFilters.houseType) {
      qFilters.houseType
        = !houseUrl.value && tariffs.agg.availableHouseTypes.includes('FLAT')
          ? 'FLAT'
          : ''
    }

    filters.value = {
      ...filters.value,
      ...smF?.tariffsInput,
      serviceSets: (
        smF?.tariffsInput?.serviceSets?.[0]?.map((el: any) => el + '') || smF?.tariffsInput?.serviceSetsContains?.[0]?.map((el: any) => el + '') || []
      )?.flat(),
      ...qFilters,
    }
    Object.keys(ranges.value).forEach((el) => {
      if (!qFilters[el] && !filters.value[el]) {
        filters.value[el]
          = tariffs?.agg?.[`${el.replace(/M.*/, '')}Range`]?.[
            `m${el.replace(/.*M/, '')}`
          ]
        ranges.value[el] = false
      }
      else {
        ranges.value[el] = true
      }
    })
  }

  return {
    filters,
    ranges,
    setFiltersOnMounted,
    fetchTariffs,
    fastFetchTariffs,
    fetchProvidersWTariffs,
  }
}
